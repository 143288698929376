import React from "react";
import Form from "./common/form";
import Joi from "joi-browser";
import { API_BASE_URL } from "../api/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";

class Contact extends Form {
   constructor(props) {
      super(props);
      this.state = {
         data: {
            firstName: "",
            lastName: "",
            email: "",
            message: "",
         },
         errors: {},
      };
   }

   schema = {
      firstName: Joi.string().required().label("First name"),
      lastName: Joi.string().required().label("Last name"),
      email: Joi.string().email().required().label("Email"),
      product: Joi,
      message: Joi,
   };

   doSubmit = async () => {
      const { email, message, firstName, lastName } = this.state.data;
      try {
         await axios.post(API_BASE_URL + "contact", {
            email: email,
            message: `From: ${firstName} ${lastName} \r\nEmail: ${email}\r\nMessage: ${message}
        `,
         });
         toast("Message sent");
         this.setState({
            data: {
               firstName: "",
               lastName: "",
               email: "",
               message: "",
            },
         });
      } catch (error) {
         toast.error("Something went wrong");
      }
   };

   render() {
      return (
         <div className='main__background'>
            <main className='main container'>
               <h1>Contact</h1>
               <div className='contact--form'>
                  <section className='contact--text'>
                     <p>
                        <strong>Please fill out the form to contact me.</strong>
                     </p>
                     <p>
                        <strong>
                           I will get back to you as soon as possible.
                        </strong>
                     </p>
                     {/*<p className='modal--link' onClick={this.props.onClick}>
                        Se stoffer her..
      </p>*/}
                  </section>
                  <div className='block form--section'>
                     <form onSubmit={this.handleSubmit}>
                        {this.renderInput(
                           "firstName",
                           "First name",
                           "text",
                           "placeholder: 'Name'"
                        )}
                        {this.renderInput("lastName", "Last name", "text")}
                        {this.renderInput("email", "Email", "email")}
                        {this.renderTextarea("message", "Message")}
                        {this.renderButton("Send")}
                     </form>
                  </div>
               </div>
            </main>
         </div>
      );
   }
}

export default Contact;
