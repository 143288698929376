import React, { useEffect, useState } from "react";

import Table from "../../common/table";
import { getOrders } from "../../../api/httpServices";
import Pagination from "../../common/Pagination";

const Orders = ({ user }) => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const pageSize = 8;

  const headers = {
    headers: {
      Authorization: "Bearer " + user.token,
    },
  };

  const sortOrders = (orders) => {
    return orders.sort(
      (a, b) => new Date(b.last_modified_at) - new Date(a.last_modified_at)
    );
  };

  const getColor = (data) => {
    return data?.split(" ")[1];
  };

  const getOrdersDb = async () => {
    const dbOrders = await getOrders(headers);
    sortOrders(dbOrders.data);
    setOrders(dbOrders.data);
  };

  const filterOrders = (page) => {
    const startIndex = (page - 1) * pageSize;
    return orders.slice(startIndex, startIndex + pageSize);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setFilteredOrders(filterOrders(page));
  };

  const columns = [
    {
      path: "billing_address.given_name",
      label: "Name",
      content: (order) => {
        return (
          order.billing_address.given_name +
          " " +
          order.billing_address.family_name
        );
      },
    },
    {
      path: "billing_address.email",
      label: "Email",
      key: "email",
    },
    {
      path: "billing_address",
      label: "Address",
      key: "address",
      content: (order) => {
        return (
          <div>
            {order.billing_address.street_address}
            <br />
            {order.billing_address.postal_code}
            <br />
            {order.billing_address.city}
          </div>
        );
      },
    },
    {
      path: "status",
      label: "Status",
    },
    {
      path: "order_amount",
      label: "Amount",
      content: (order) => {
        return parseInt(order.order_amount).toFixed(2);
      },
    },
    {
      path: "last_modified_at",
      label: "Date",
      content: (order) => {
        return order.last_modified_at.substr(0, 10);
      },
    },
    {
      path: "order_lines",
      label: "Order Lines",
      content: (order) => {
        return order.order_lines.map((line) => {
          return (
            <div>
              {line.quantity} - {line.name} - {getColor(line.merchant_data)}
            </div>
          );
        });
      },
    },
  ];

  useEffect(() => {
    if (orders.length === 0) {
      getOrdersDb();
    }

    setFilteredOrders(filterOrders(1));
  }, [orders]);

  return (
    <div className="main container">
      <h1>Orders</h1>
      <Table columns={columns} data={filteredOrders} />
      <Pagination
        pageSize={pageSize}
        itemsCount={orders.length}
        onPageChange={handlePageChange}
        currentPage={currentPage}
      />
    </div>
  );
};

export default Orders;
