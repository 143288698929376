import React, { useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../common/table";

import { deleteProductDb } from "../../../api/httpServices";
import { deleteProduct } from "../../../redux/reducers/productsSlice";
import { useDispatch } from "react-redux";
import DeleteModal from "../deleteModal";

const ProductTable = ({ products, user }) => {
   const [toggleModal, setToggleModal] = useState(false);
   const [deleteId, setDeleteId] = useState(null);

   const dispatch = useDispatch();
   const headers = {
      headers: {
         Authorization: "Bearer " + user.token,
      },
   };

   const handleClick = async (id) => {
      if (!toggleModal) {
         setToggleModal(true);
         setDeleteId(id);
         return;
      }

      try {
         await deleteProductDb(id, headers);
         dispatch(deleteProduct({ _id: id }));
         setDeleteId(null);
      } catch (error) {
         console.log(error.message);
      }
   };
   const columns = [
      {
         path: "productName",
         label: "Name",
         content: (product) => {
            return (
               <Link to={`/admin/products/${product._id}`}>
                  {product.style.styleName}
               </Link>
            );
         },
      },
      {
         path: "images",
         label: "",
         content: (product) => {
            if (product.style.images.length > 0) {
               return (
                  <img
                     src={product.style.images[0].url}
                     style={{ width: 100, height: 100 }}
                     alt='Ballet skirt product'
                  ></img>
               );
            }
         },
      },
      { path: "fabric.fabricName", label: "Fabric" },
      { path: "color.colorName", label: "Colour" },
      { path: "waistBand", label: "Waist band" },
      { path: "style.price", label: "Price" },

      {
         key: "numberInStock",
         label: "Stock",
         content: (product) => {
            return product.amountOnStock;
         },
      },
      {
         key: "delete",
         content: (product) => {
            return (
               <button
                  className='btn btn--outline'
                  onClick={() => handleClick(product._id)}
               >
                  Delete
               </button>
            );
         },
      },
   ];

   const handleDelete = (status) => {
      if (status) {
         handleClick(deleteId);
      }
      setToggleModal(false);
   };

   return (
      <main className='main container '>
         <h1>Products in DB</h1>
         <div>
            <Link to='/admin/products/product'>
               <button className='btn btn--outline'>New product</button>
            </Link>
         </div>
         <div className='productsTable'>
            <Table columns={columns} data={products} />
         </div>
         <div>{toggleModal ? <DeleteModal onClick={handleDelete} /> : ""}</div>
      </main>
   );
};

export default ProductTable;
