import React from "react";

import { useParams } from "react-router-dom";
import ItemDetails from "../common/ItemDetails";
import ProductCard from "../common/productCard";

const Products = ({ products }) => {
   const params = useParams();
   let product = null;

   product = products?.find((p) => p._id === params.id);

   return (
      <div className='main__background'>
         <main className='main container'>
            {product ? <h1>Product Details</h1> : <h1>Ready to wear</h1>}
            <div>
               {!product ? <h3>Skirts ready to wear and order!</h3> : null}
            </div>
            {product ? (
               <div className='block'>
                  <ItemDetails product={product} />
               </div>
            ) : (
               <div className=' block grid small-container  '>
                  {products.map((product) => {
                     if (product.amountOnStock > 0)
                        return (
                           <ProductCard product={product} key={product._id} />
                        );
                  })}
               </div>
            )}
         </main>
      </div>
   );
};

export default Products;
