import React from "react";
import Accordion from "./common/Accordion";

const Footer = ({ onClick }) => {
  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="footer__logo">
          <img src="/images/whiteLogoFooter.jpg" alt="" />
        </div>
        <section className="footer__social">
          <div className="social--wrapper">
            <p>
              <a href="https://www.facebook.com/augustiniuswear">
                <i className="fa-brands fa-facebook-square"></i>
              </a>
            </p>
            <p>
              <a
                href="https://instagram.com/augustiniuswear"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
            </p>
          </div>
        </section>
        <Accordion
          title="Contact"
          content={
            <p>
              <span className="footer__icon">
                <i className="fa-solid fa-envelope"></i>
              </span>

              <a href="mailto://noraelise@me.com">noraelise@me.com</a>
            </p>
          }
        />
        <Accordion title="Site map" content={<SiteMap onClick={onClick} />} />
      </div>
    </div>
  );
};

export default Footer;

const SiteMap = ({ onClick }) => {
  return (
    <ul className="list">
      <li>
        <a href="/">Home</a>
      </li>
      <li>
        <a href="/styles">Styles</a>
      </li>
      <li>
        <a href="/products">Ready to wear</a>
      </li>
      <li className="nav__item" onClick={onClick}>
        Colours
      </li>
      <li>
        <a href="/about">About me</a>
      </li>
      <li>
        <a href="/gallery">Gallery</a>
      </li>
      <li>
        <a href="/contact">Contact</a>
      </li>
    </ul>
  );
};
