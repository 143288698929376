import React, { useEffect } from "react";

let checkoutRef = null;
function setDangerousHtml(html) {
   if (checkoutRef === null) {
      return;
   }

   const range = document.createRange();

   range.selectNodeContents(checkoutRef);
   range.deleteContents();

   checkoutRef.appendChild(range.createContextualFragment(html));
}

const KlarnaCheckout = ({ snippet }) => {
   useEffect(() => {
      if (snippet) {
         setDangerousHtml(snippet);
      }
   }, [snippet]);
   return (
      <>
         {snippet.length ? (
            <div
               ref={(ref) => (checkoutRef = ref)}
               suppressHydrationWarning={true}
            />
         ) : (
            <h3>No items in cart!</h3>
         )}
      </>
   );
};

export default KlarnaCheckout;
