import React from "react";

import CartItem from "./cartItem";
import CartTotal from "./cartTotal";

const Cart = ({ cart, klarna }) => {
   return (
      <div className='main__background'>
         <main className='main container'>
            <h1>Cart</h1>
            <p>
               We currently only ship within Norway. Please contact me for any
               questions.
            </p>
            {cart.length === 0 ? (
               <h2>You have no items in your shopping cart!</h2>
            ) : (
               <div className='cart--wrapper'>
                  <div className='cart--products-wrapper'>
                     {cart.map((item) => {
                        return (
                           <CartItem
                              item={item}
                              key={item._id + item.color._id}
                           />
                        );
                     })}
                  </div>
                  <CartTotal cart={cart} klarna={klarna} />
               </div>
            )}
         </main>
      </div>
   );
};

export default Cart;
