import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import UploadImage from "../UploadImage";
import ImageThumbnails from "../imageThumbnails";
import Form from "./form";

import { getUser } from "../../../redux/reducers/userSlice";

import {
  selectAllStyles,
  fetchStyles,
  updateStyle,
} from "../../../redux/reducers/styleSlice";
import { toast } from "react-toastify";

const StylesForm = () => {
  const params = useParams();
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [edit, setEdit] = useState(false);
  const user = useSelector(getUser);
  const styles = useSelector(selectAllStyles);
  const dispatch = useDispatch();
  const headers = {
    headers: {
      Authorization: "Bearer " + user.token,
    },
  };

  const handleImageSubmit = async (res) => {
    const images = {
      images: [...selectedStyle.images, { url: res.url }],
    };
    try {
      const response = await dispatch(
        updateStyle({ _id: selectedStyle._id, style: images, headers })
      ).unwrap();
      if (response.error) throw new Error(response.error.data);
      console.log(response);
      setSelectedStyle(response);
      toast.success("Style updated");
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    dispatch(fetchStyles());
    if (params.id !== "style") {
      let style = styles.filter((style) => style._id === params.id);
      if (style.length > 0) {
        setSelectedStyle(style[0]);
        setEdit(true);
      }
    }
  }, [params.id]);

  return (
    <main className="main small-container">
      <h1>Styles</h1>

      <Form selectedStyle={selectedStyle} edit={edit} headers={headers} />

      {edit ? (
        <div>
          <div className="block">
            <h2>Images</h2>
            <p>
              {selectedStyle ? (
                <ImageThumbnails selectedProduct={selectedStyle} />
              ) : (
                ""
              )}
            </p>
            <p>
              <UploadImage
                filename="style-image"
                handleImageSubmit={handleImageSubmit}
              />
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </main>
  );
};

export default StylesForm;
