import React from "react";

const Pagination = ({ pageSize, itemsCount, onPageChange, currentPage }) => {
   const pagesCount = Math.ceil(itemsCount / pageSize);
   if (pagesCount === 0) return null;

   const pages = [...Array(pagesCount).keys()].map((x) => x + 1);
   if (pages.length > 1)
      return (
         <nav aria-label='Page navigation'>
            <ul className='pagination'>
               {pages.map((page) => {
                  return (
                     <li
                        key={page}
                        className={
                           page === currentPage
                              ? "page-item active"
                              : "page-item"
                        }
                     >
                        <a
                           className='page-link'
                           onClick={() => onPageChange(page)}
                        >
                           {page}
                        </a>
                     </li>
                  );
               })}
            </ul>
         </nav>
      );
   return null;
};

export default Pagination;
