import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import { addColorDb, updateColorDb } from "../../../api/httpServices";
import { addColor, updateColor } from "../../../redux/reducers/colorSlice";

import { IKImage } from "imagekitio-react";

const FabricsForm = ({ colors, user }) => {
  const initialFormData = {
    colorName: "",
    image: "",
  };

  const headers = {
    headers: {
      Authorization: "Bearer " + user.token,
    },
  };

  const params = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [selectedColor, setSelectedColor] = useState({});
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();

  const validateForm = () => {
    const { error } = schema.validate(formData, { abortEarly: false });

    const errors = {};

    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
      setFormErrors(errors);
      console.log(errors);
      return false;
    }

    return true;
  };

  const schema = Joi.object({
    fabricName: Joi.string().min(3).max(50).required().label("Fabric Name"),
    image: Joi.empty(""),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (!edit) {
      try {
        const response = await addColorDb(formData, headers);
        dispatch(addColor(response.data));
        setSelectedColor(response.data);
        toast.success("Color added!");
        navigate(`/admin/fabrics/${response.data._id}`);
      } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong!");
      }
    } else {
      try {
        const response = await updateColorDb(
          selectedColor._id,
          formData,
          headers
        );
        dispatch(updateColor(response.data));
        toast.success("Product updated");
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageSubmit = async (res) => {
    console.log(res);
    const fabric = {
      image: res.url,
    };
    try {
      const response = await updateColorDb(selectedColor._id, fabric, headers);
      dispatch(updateColor(response.data));
      setSelectedColor(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id !== "color") {
      let color = colors.filter((color) => color._id === params.id);
      if (color.length > 0) {
        setSelectedColor(color[0]);
        setFormData({
          colorName: color[0].colorName,
          images: color.images,
        });
        setEdit(true);
      }
    }
  }, [params.id]);

  return (
    <div className="main small-container">
      <h1>Fabrics Form</h1>
      <form onSubmit={handleSubmit} className="flex--col">
        <div className="form-group">
          <label htmlFor="productName">Fabric name</label>
          <input
            className="form-control"
            type="text"
            id="fabricName"
            name="fabricName"
            placeholder="Fabric Name"
            onChange={handleChange}
            value={formData.fabricName}
          />
          {formErrors.fabricName ? (
            <div className="error">{formErrors.fabricName}</div>
          ) : (
            ""
          )}
        </div>

        <button className="btn btn--outline">
          {edit ? "Update fabric" : "Submit"}
        </button>
      </form>
      {edit ? (
        <>
          <div className="block">
            {selectedColor.image ? (
              <IKImage
                urlEndpoint="https://ik.imagekit.io/designedit"
                src={selectedColor.image}
                transformation={[{ height: 100, width: 100 }]}
              />
            ) : (
              "No image"
            )}
          </div>
          <div></div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default FabricsForm;
