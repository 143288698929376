import React from "react";

import { useState } from "react";
import "./gallery.css";

const GalleryComponent = ({ galleryImages }) => {
   const [slideNumber, setSlideNumber] = useState(0);
   const [openModal, setOpenModal] = useState(false);

   const handleClick = (index) => {
      setSlideNumber(index);
      setOpenModal(true);
   };
   const handleModalClose = () => {
      setOpenModal(false);
   };

   return (
      <>
         <div className='galleryWrap'>
            {galleryImages &&
               galleryImages.map((slide, index) => {
                  return (
                     <div
                        className='single'
                        key={index}
                        onClick={() => handleClick(index)}
                     >
                        <img src={slide.url} alt='' />
                     </div>
                  );
               })}
         </div>

         <div
            className={`modalWrap ${openModal ? "active" : ""}`}
            onClick={handleModalClose}
         >
            <div className='appModal'>
               <span className='close' onClick={handleModalClose}>
                  &times;
               </span>
               <div className='imageWrap'>
                  <img src={galleryImages[slideNumber]?.url} alt='Product' />
               </div>
            </div>
         </div>
      </>
   );
};

export default GalleryComponent;
