import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = ({ cart, onClick, user }) => {
   const [navExpand, setNavExpand] = useState(false);
   const [navWhite, setNavWhite] = useState(false);
   const [adminNav, setAdminNav] = useState(false);
   const history = useLocation();

   const navBarScroll = () => {
      if (window.scrollY >= 80) setNavWhite(true);
      else setNavWhite(false);
   };

   useEffect(() => {
      navBarScroll();
      window.addEventListener("scroll", navBarScroll);
   });

   const handleClick = () => {
      navExpand ? setNavExpand(false) : setNavExpand(true);
   };

   const getNavClasses = () => {
      let classes = "nav collapsible ";
      if (history.pathname !== "/" || navWhite) classes += "nav--white ";
      if (navExpand) classes += "collapsible--expanded ";
      return classes;
   };

   const getItemsTotal = () => {
      let items = 0;
      if (cart.length > 0) {
         cart.forEach((item) => {
            items += item.quantity;
         });
      }
      return items;
   };

   const animatedLogo = () => {
      return (
         <div className='logo'>
            <img
               className='logoImage'
               src='/images/NORA-SORT.png'
               alt='Logo of dancer'
            />
         </div>
      );
   };

   const handleAdminClick = () => {
      adminNav ? setAdminNav(false) : setAdminNav(true);
   };

   return (
      <div className={getNavClasses()}>
         <nav className='navbar container'>
            <Link to='/'>
               <div className='navbar__brand'>
                  {history.pathname === "/" ? animatedLogo() : ""}
                  {/*<img
              className="logoImage"
              src="/images/Logo-Transparent-versjon.png"
              alt=""
            />*/}

                  <img
                     className='logoImage'
                     src='/images/NORA-SORT.png'
                     alt='Logo of dancer'
                  />

                  <h2 className='navbar__brand-heading'>AUGUSTINIUS</h2>
               </div>
            </Link>
            <div className='nav--icons'>
               <div className='nav__cart__icon'>
                  <Link to='/cart'>
                     <i className='icon fa-solid fa-cart-shopping'></i>
                     {getItemsTotal() > 0 ? (
                        <span className=' nav__cart nav__cart-badge'>
                           {getItemsTotal()}
                        </span>
                     ) : (
                        ""
                     )}
                  </Link>
               </div>
               {user.token ? (
                  <div className='nav__cart__icon' onClick={handleAdminClick}>
                     <i className='icon fa-solid fa-user'></i>
                     <div
                        className={adminNav ? "adminNav display" : "adminNav"}
                     >
                        <Link to='/admin/products'>Products</Link>
                        <Link to='/admin/styles'>Styles</Link>
                        <Link to='/admin/colors'>Colours</Link>
                        <Link to='/admin/orders'>Orders</Link>
                        <Link to='/logout'>Log out</Link>
                     </div>
                  </div>
               ) : (
                  ""
               )}

               <div className='navbar__toggler' onClick={handleClick}>
                  <i className='fa-solid fa-bars'></i>
               </div>
            </div>

            <ul className='list nav__list collapsible__content'>
               <li className='nav__items'>
                  <Link to='/styles' onClick={handleClick}>
                     Styles
                  </Link>
               </li>
               <li className='nav__items'>
                  <Link to='/products' onClick={handleClick}>
                     Ready to wear
                  </Link>
               </li>
               <li className='nav__items modal--link' onClick={onClick}>
                  Colours
               </li>
               <li className='nav__items' onClick={handleClick}>
                  <Link to='/about'>About me</Link>
               </li>
               <li className='nav__items' onClick={handleClick}>
                  <Link to='/gallery'>Gallery</Link>
               </li>
               <li className='nav__items' onClick={handleClick}>
                  <Link to='/contact'>Contact</Link>
               </li>
            </ul>
         </nav>
      </div>
   );
};

export default Navbar;
