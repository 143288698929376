import React from "react";

const DeleteModal = ({ onClick }) => {
   return (
      <div className='deleteModal'>
         <div className='deleteModal__box'>
            <div className='deleteModal__header'>
               <h4>Are you sure you want to delete the product?</h4>
            </div>
            <div className='deleteModal__footer'>
               <button
                  className='btn btn--outline btn--cancel'
                  onClick={() => onClick(false)}
               >
                  Cancel
               </button>
               <button
                  className='btn btn--outline btn--confirm'
                  onClick={() => onClick(true)}
               >
                  Confirm
               </button>
            </div>
         </div>
      </div>
   );
};

export default DeleteModal;
