import React from "react";

import { API_BASE_URL } from "../../api/apiConfig";
import { IKContext, IKImage } from "imagekitio-react";

const publicKey = "public_s0GVIAV3ngWyzp/MT3+Dgosr/4o=";
const urlEndpoint = "https://ik.imagekit.io/designedit";
const authenticationEndpoint = API_BASE_URL + "imagekit/auth";

const RenderIKImage = ({ path, transformation }) => {
   return (
      <IKContext
         urlEndpoint={urlEndpoint}
         publicKey={publicKey}
         authenticationEndpoint={authenticationEndpoint}
      >
         <IKImage path={path} transformation={transformation} />
      </IKContext>
   );
};

export default RenderIKImage;
