import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../api/apiConfig";

const API_URL = API_BASE_URL + "klarna/";

export const getKlarnaOrder = createAsyncThunk(
   "order/getKlarnaOrder",
   async (klarnaOrderId) => {
      const response = await axios.get(API_URL + klarnaOrderId);
      return response.data;
   }
);

const initialState = {
   order: {
      order_lines: [],
      order_amount: 0,
   },
   klarna: {
      klarnaOrderNumber: null,
      klarnaStatus: "idle", //idle || checkout || confirm
      error: null,
      html_snippet: {},
   },
};
const orderSlice = createSlice({
   name: "order",
   initialState: initialState,
   reducers: {
      setOrder: (state, action) => {
         state.order.order_lines = action.payload.order_lines;
         state.order.order_amount = action.payload.order_amount;
         state.klarna.klarnaStatus = "checkout";
      },
      setHtmlSnippet: (state, action) => {
         state.klarna.html_snippet = action.payload;
      },
      setKlarnaOrderNumber: (state, action) => {
         state.klarna.klarnaOrderNumber = action.payload;
      },
      setKlarnaStatus: (state, action) => {
         state.klarna.klarnaStatus = action.payload;
      },
      clearOrder: (state, action) => {
         state.order.order_lines = [];
         state.order.order_amount = 0;
         state.klarna = {
            klarnaOrderNumber: null,
            error: null,
            html_snippet: {},
         };
      },
   },
   extraReducers(builder) {
      builder
         .addCase(getKlarnaOrder.fulfilled, (state, action) => {
            state.klarna.klarnaStatus = "confirm";
            state.klarna.html_snippet = action.payload.html_snippet;
         })
         .addCase(getKlarnaOrder.rejected, (state, action) => {
            state.klarna.error = "Not successful in getKlarnaOrder";
         });
   },
});

export const getOrderState = (state) => state.order;
export const selectKlarna = (state) => state.order.klarna;

export const {
   setOrder,
   setHtmlSnippet,
   setKlarnaOrderNumber,
   setKlarnaStatus,
   clearOrder,
} = orderSlice.actions;
export default orderSlice.reducer;
