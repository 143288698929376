import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/reducers/cartSlice";

import { toast } from "react-toastify";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import ProductInfo from "./ProductInfo";

const ItemDetails = ({ style = null, product = null }) => {
  const dispatch = useDispatch();
  const [item, setItem] = useState(null);

  useEffect(() => {
    if (product !== null) {
      if (product !== null && product.style.images.length > 0) {
        setItem(product.style);
      }
    }
  }, [product]);

  useEffect(() => {
    if (style !== null) {
      if (style !== null && style.images.length > 0) setItem(style);
    }
  }, [style]);

  const handleBuy = (product) => {
    try {
      dispatch(addToCart(product));
      toast.success("Product added to cart");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="product--details grid grid--1x2">
      <section className="product--image">
        <div>
          <Carousel
            renderIndicator={false}
            ariaLabel="product"
            dynamicHeight={true}
            showThumbs={false}
          >
            {item?.images?.map((image) => (
              <div key={image.url}>
                <img src={image.url} alt="ballet skirt" />
              </div>
            ))}
          </Carousel>
        </div>
      </section>

      <div className="product__details-wrapper">
        <section className="product__details-description">
          <h2>{item?.styleName}</h2>
          <p className="product__price">NOK {item?.price.toFixed(2)},-</p>
          <p className="product__text">{item?.shortDescription}</p>
          <p className="product__text">{item?.longDescription}</p>
          <hr className="product--details-horisontal" />
        </section>

        {product ? (
          <>
            <section className="product__details-info">
              <div>
                <ul>
                  <li>Colour: {product?.color.colorName}</li>
                  <li>Fabric: {product?.fabric.fabricName}</li>
                  <li>Waist band: {product?.waistBand}</li>
                </ul>
                <div className="product__details-colors">
                  {product?.color.images.map((image) => (
                    <div
                      style={{ width: "100px", height: "100px" }}
                      key={image.url}
                    >
                      <img
                        src={image.url}
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                        alt="product color"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </section>

            <div className="product__details-order-wrapper">
              <button
                className="btn btn--outline"
                onClick={() => handleBuy(product)}
              >
                Add to cart
              </button>
            </div>
          </>
        ) : (
          <section>
            <div className="product__details-info">
              <ProductInfo />
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default ItemDetails;
