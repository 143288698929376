import React from "react";
import Hero from "./hero";

import { useNavigate } from "react-router-dom";
import AvailableProducts from "./availableProducts";

const Home = ({ products }) => {
   const navigate = useNavigate();

   const handleClick = (id) => {
      navigate(`/products/${id}`);
   };

   return (
      <>
         <Hero />
         <div className='main__background'>
            <section className='container offer block'>
               <div className='offer__heading'>
                  <span className='header__line'></span>
                  <h3>Ready to wear</h3>
                  <span className='header__line'></span>
               </div>
               <p>
                  Below are the skirts that are produced and ready to wear.
                  Click on your favourite to purchase.
               </p>
               <AvailableProducts onClick={handleClick} products={products} />
            </section>
            <section className='main_background'></section>
         </div>
      </>
   );
};

export default Home;
