import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
   addProductDb,
   updateProductDb,
   getProducts,
   decreaseStockDb,
} from "../../api/httpServices";

export const fetchProducts = createAsyncThunk(
   "products/fetchProducts",
   async () => {
      const response = await getProducts();
      return response;
   }
);

export const addProduct = createAsyncThunk(
   "products/addProducts",
   async (product, { rejectWithValue }) => {
      try {
         const response = await addProductDb(product.product, product.headers);
         return response.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

export const updateProduct = createAsyncThunk(
   "products/updateProduct",
   async (product, { rejectWithValue }) => {
      try {
         const response = await updateProductDb(
            product._id,
            product.product,
            product.headers
         );
         return response.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

export const decreaseStock = createAsyncThunk(
   "products/decreaseStock",
   async (product, { rejectWithValue }) => {
      try {
         const response = await decreaseStockDb(product._id, product.quantity);
         return response.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

const initialState = {
   products: [],
   status: "idle", // 'idle' || 'loading' || 'succeeded' || 'failed'
   error: null,
};

export const productSlice = createSlice({
   name: "products",
   initialState: initialState,
   reducers: {
      deleteProduct: (state, action) => {
         const index = state.products.findIndex(
            (product) => product._id === action.payload._id
         );
         state.products.splice(index, 1);
      },
      updateStockAmount: (state, action) => {
         const index = state.products.findIndex(
            (product) => product._id === action.payload._id
         );
         state.products[index].amountOnStock += action.payload.amount;
      },
   },
   extraReducers(builder) {
      builder
         .addCase(fetchProducts.pending, (state, action) => {
            state.status = "loading";
         })
         .addCase(fetchProducts.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.products = action.payload;
         })
         .addCase(fetchProducts.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            console.log(action.error.message);
         })
         .addCase(addProduct.fulfilled, (state, action) => {
            state.products.push(action.payload);
         })
         .addCase(updateProduct.fulfilled, (state, action) => {
            const index = state.products.findIndex(
               (product) => action.payload._id === product._id
            );

            if (index !== -1) state.products[index] = action.payload;
         })
         .addCase(decreaseStock.fulfilled, (state, action) => {
            const index = state.products.findIndex(
               (product) => action.payload._id === product._id
            );

            if (index !== -1) state.products[index] = action.payload;
         });
   },
});
export const selectAllProducts = (state) => state.products.products;
export const getProductStatus = (state) => state.products.status;
export const getProductError = (state) => state.products.error;

export const { updateStockAmount, deleteProduct } = productSlice.actions;
export default productSlice.reducer;
