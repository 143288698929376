import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Form from "./form";
import { getUser } from "../../../redux/reducers/userSlice";

const ProductForm = ({ products, fabrics }) => {
   const params = useParams();
   const [selectedProduct, setSelectedProduct] = useState(null);
   const [edit, setEdit] = useState(false);
   const user = useSelector(getUser);

   const headers = {
      headers: {
         Authorization: "Bearer " + user.token,
      },
   };

   useEffect(() => {
      if (params.id !== "product") {
         let product = products.filter((product) => product._id === params.id);
         if (product.length > 0) {
            setSelectedProduct(product[0]);
            setEdit(true);
         }
      }
   }, [products, params.id]);

   return (
      <main className='main small-container'>
         <h1>Product Form</h1>

         <Form
            selectedProduct={selectedProduct}
            edit={edit}
            headers={headers}
         />
      </main>
   );
};

export default ProductForm;
