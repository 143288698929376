import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./reducers/productsSlice";
import cartReducer from "./reducers/cartSlice";
import orderReducer from "./reducers/orderSlice";
import colorReducer from "./reducers/colorSlice";
import userReducer from "./reducers/userSlice";
import styleReducer from "./reducers/styleSlice";

export const store = configureStore({
   reducer: {
      products: productReducer,
      cart: cartReducer,
      order: orderReducer,
      colors: colorReducer,
      user: userReducer,
      styles: styleReducer,
   },
});
