import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../api/apiConfig";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/reducers/userSlice";
import { useNavigate } from "react-router-dom";

const Login = ({ user }) => {
   let navigate = useNavigate();

   const [userName, setUserName] = useState("");
   const [password, setPassword] = useState("");
   const dispatch = useDispatch();

   const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await axios.post(API_BASE_URL + "user/login", {
         email: userName,
         password,
      });
      const data = response.data;
      if (data.token) {
         setUserName("");
         setPassword("");
         dispatch(setUser(data));

         toast("Login Successful");
      } else {
         toast("Please check your username and/or password");
      }
   };

   useEffect(() => {
      if (user.token) navigate("/admin/products", { replace: true });
   }, [user]);

   return (
      <div className='main__background'>
         <main className='main container'>
            <h1>Login</h1>
            <div className='center block'>
               <div className='card '>
                  <form onSubmit={handleSubmit}>
                     <div className='form-group'>
                        <input
                           name='username'
                           id='username'
                           type='text'
                           placeholder='User Name'
                           onChange={(e) => setUserName(e.target.value)}
                           value={userName}
                           autoComplete='current-username'
                        />
                     </div>
                     <div className='form-group'>
                        <input
                           name='password'
                           id='password'
                           type='password'
                           placeholder='Pasword'
                           onChange={(e) => setPassword(e.target.value)}
                           value={password}
                           autoComplete='current-password'
                        />
                     </div>
                     <button type='submit' className='btn btn--outline'>
                        Login
                     </button>
                  </form>
               </div>
            </div>
         </main>
      </div>
   );
};

export default Login;
