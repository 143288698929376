import React from "react";
import { Link } from "react-router-dom";

const Hero = () => {
   return (
      <div className='hero'>
         <div className='hero__content container'>
            <div className='hero__title'>
               <h1 className='brand--heading'>AUGUSTINIUS WEAR</h1>
               <h2 className='hero__title-heading'>
                  Ballet skirts handmade with love
                  <i className='hero__icon fa-solid fa-heart'></i>
               </h2>
               <p>
                  <strong>by Nora Augustinius</strong>
               </p>
               <Link to='/styles'>
                  <div className='hero__products-link'>
                     <button className='btn btn--outline'>See styles</button>
                  </div>
               </Link>
            </div>
         </div>
      </div>
   );
};

export default Hero;
