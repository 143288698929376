import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { getKlarnaOrder, clearOrder } from "../../redux/reducers/orderSlice";
import { clearCart } from "../../redux/reducers/cartSlice";
import { decreaseStock } from "../../redux/reducers/productsSlice";
import { toast } from "react-toastify";
import { confirmKlarnaOrder, createOrder } from "../../api/httpServices";
import KlarnaCheckout from "./KlarnaCheckoutSnippet";

const Confirmation = ({ klarna }) => {
   const dispatch = useDispatch();
   const params = useParams();
   const [snippet, setSnippet] = useState(null);

   useEffect(() => {
      if (params.id) confirmOrder(params.id);

      return () => {
         //Clear order on exit page
         dispatch(clearOrder());
      };
   }, []);

   const updateStockAmounts = async (orderLines) => {
      const stockDecreaseAmounts = [];
      orderLines.forEach((product) => {
         if (product.reference !== "Norgespakke")
            stockDecreaseAmounts.push({
               _id: product.reference,
               quantity: product.quantity,
            });
      });

      stockDecreaseAmounts.forEach(async (item) => {
         try {
            const response = await dispatch(
               decreaseStock({
                  _id: item._id,
                  quantity: item.quantity,
               })
            ).unwrap();
            if (response.error) throw new Error(response.error);
         } catch (error) {
            toast.error(error.message);
         }
      });
   };

   const confirmOrder = async (id) => {
      try {
         //Get Klarna order updated for confirmation and set snippet
         const order = await dispatch(getKlarnaOrder(id)).unwrap();
         if (order.error) throw new Error(order.error);
         setSnippet(order.html_snippet);

         await confirmKlarnaOrder(id);

         // Update stock amount for Products in DB
         const orderLines = order.order_lines;
         await updateStockAmounts(orderLines);

         await createOrder(order);

         dispatch(clearCart());
      } catch (error) {
         toast.error(error.message);
      }
   };

   return (
      <main className='main container'>
         <h1>Confirmation</h1>
         {snippet ? <KlarnaCheckout snippet={snippet} /> : null}
      </main>
   );
};

export default Confirmation;
