import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Table from "../../common/table";
import DeleteModal from "../deleteModal";
import {
   selectAllStyles,
   fetchStyles,
} from "../../../redux/reducers/styleSlice";
import { deleteStyle } from "../../../redux/reducers/styleSlice";
import { toast } from "react-toastify";

const ProductTable = ({ user }) => {
   const [toggleModal, setToggleModal] = useState(false);
   const [deleteId, setDeleteId] = useState(null);
   const styles = useSelector(selectAllStyles);

   const dispatch = useDispatch();

   const headers = {
      headers: {
         Authorization: "Bearer " + user.token,
      },
   };

   const handleClick = async (id) => {
      if (!toggleModal) {
         setToggleModal(true);
         setDeleteId(id);
         return;
      }

      try {
         const response = dispatch(deleteStyle({ _id: id, headers })).unwrap();
         if (response.error) throw new Error(response.error.data);
         setDeleteId(null);
      } catch (error) {
         toast.error(error.message);
      }
   };
   const columns = [
      {
         path: "styleName",
         label: "Name",
         content: (style) => {
            return (
               <Link to={`/admin/styles/${style._id}`}>{style.styleName}</Link>
            );
         },
      },
      {
         path: "images",
         label: "",
         content: (style) => {
            if (style.images.length > 0) {
               return (
                  <img
                     src={style.images[0].url}
                     style={{ width: 100, height: 100 }}
                     alt='Ballet skirt product'
                  ></img>
               );
            }
         },
      },
      { path: "shortDescription", label: "Short Desc" },
      { path: "longDescription", label: "Long Desc" },
      { path: "price", label: "Price" },
      {
         path: "nyhet",
         label: "Nyhet",
         content: (style) => {
            if (style.nyhet) return "Nyhet";
         },
      },
      {
         key: "delete",
         content: (style) => {
            return (
               <button
                  className='btn btn--outline'
                  onClick={() => handleClick(style._id)}
               >
                  Delete
               </button>
            );
         },
      },
   ];

   const handleDelete = (status) => {
      if (status) {
         handleClick(deleteId);
      }
      setToggleModal(false);
   };

   useEffect(() => {
      dispatch(fetchStyles());
   }, []);

   return (
      <main className='main container '>
         <h1>Styles in DB</h1>
         <div>
            <Link to='/admin/styles/style'>
               <button className='btn btn--outline'>New style</button>
            </Link>
         </div>
         <div className='productsTable'>
            <Table columns={columns} data={styles} />
         </div>
         <div>{toggleModal ? <DeleteModal onClick={handleDelete} /> : ""}</div>
      </main>
   );
};

export default ProductTable;
