import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("token");
const admin = localStorage.getItem("admin");

export const userSlice = createSlice({
   name: "user",
   initialState: {
      user: "",
      token: token || "",
      admin: admin || false,
   },
   reducers: {
      setUser: (state, action) => {
         state.user = action.payload.user;
         state.token = action.payload.token;
         state.admin = action.payload.admin;
         localStorage.setItem("token", action.payload.token);
         localStorage.setItem("admin", action.payload.admin);
      },
      logOutUser: (state, action) => {
         state.user = "";
         state.token = "";
         state.admin = false;
         localStorage.removeItem("token");
         localStorage.removeItem("admin");
      },
   },
});
export const getUser = (state) => state.user;
export const { setUser, logOutUser } = userSlice.actions;
export default userSlice.reducer;
