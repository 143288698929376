import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import AOS from "aos";

import Home from "./components/Home/home";
import Navbar from "./components/navbar";
import Products from "./components/Products/products";
import Footer from "./components/footer";
import Cart from "./components/Cart/cart";
import Checkout from "./components/Cart/checkoutKlarna";
import Confirmation from "./components/Cart/confirmationKlarna";
import Contact from "./components/contact";
import Styles from "./components/styles/Styles";
import About from "./components/about";
import FabricModal from "./components/common/fabrics";

import "./css/normalize.css";
import "react-toastify/dist/ReactToastify.css";
import "./css/styles.css";
import "./css/test.css";
import "aos/dist/aos.css";

import {
  getProductStatus,
  selectAllProducts,
  fetchProducts,
} from "./redux/reducers/productsSlice";
import { selectKlarna } from "./redux/reducers/orderSlice";
import {
  selectAllColors,
  fetchColors,
  selectColorStatus,
} from "./redux/reducers/colorSlice";
import { getUser } from "./redux/reducers/userSlice";

import Login from "./components/login";
import Logout from "./components/logout";
import ProductTable from "./components/admin/products/productTable";
import ProductForm from "./components/admin/products/productForm";
import ColorsTable from "./components/admin/colors/ColorsTable";
import StylesTable from "./components/admin/styles/StylesTable";
import FabricsForm from "./components/admin/fabrics/form";
import StylesForm from "./components/admin/styles/StylesForm";
import Orders from "./components/admin/orders/Orders";
import ErrorBoundary from "./components/common/ErrorBoundary";
import { fetchStyles } from "./redux/reducers/styleSlice";
import Gallery from "./components/gallery/Gallery";

function App() {
  const products = useSelector(selectAllProducts);
  const productStatus = useSelector(getProductStatus);
  const klarna = useSelector(selectKlarna);
  const cart = useSelector((state) => state.cart.cart);
  const colors = useSelector(selectAllColors);
  const colorsStatus = useSelector(selectColorStatus);
  const user = useSelector(getUser);

  const [toggleModal, setToggleModal] = useState(false);
  const dispatch = useDispatch();

  const handleToggleModal = () => {
    toggleModal ? setToggleModal(false) : setToggleModal(true);
  };

  useEffect(() => {
    if (productStatus === "idle") dispatch(fetchProducts());
    if (colorsStatus === "idle") dispatch(fetchColors());

    AOS.init();
  }, []);

  useEffect(() => {
    dispatch(fetchStyles());
  }, []);

  const ProtectedRoute = ({ children }) => {
    const { token } = user;
    if (!token) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  return (
    <div>
      <Navbar cart={cart} onClick={handleToggleModal} user={user} />
      <ErrorBoundary>
        <Routes>
          <Route exact path="/" element={<Home products={products} />} />
          <Route path="/login" element={<Login user={user} />} />
          <Route path="/logout" element={<Logout user={user} />} />
          <Route
            path="/products"
            element={<Products products={products} />}
          ></Route>
          <Route
            path="/products/:id"
            element={<Products products={products} />}
          />
          <Route path="/styles" element={<Styles />}></Route>
          <Route path="/styles/:id" element={<Styles />}></Route>
          <Route
            path="/contact"
            element={<Contact onClick={handleToggleModal} />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/cart" element={<Cart cart={cart} klarna={klarna} />} />
          <Route path="/checkout" element={<Checkout klarna={klarna} />} />
          <Route
            path="/confirmation/:id"
            element={<Confirmation klarna={klarna} />}
          />
          <Route
            path="/admin/products"
            element={
              <ProtectedRoute>
                <ProductTable products={products} user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/colors"
            element={
              <ProtectedRoute>
                <ColorsTable colors={colors} user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/styles"
            element={
              <ProtectedRoute>
                <StylesTable user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/styles/:id"
            element={
              <ProtectedRoute>
                <StylesForm user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/colors/:id"
            element={
              <ProtectedRoute>
                <FabricsForm colors={colors} user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/products/:id"
            element={
              <ProtectedRoute>
                <ProductForm products={products} colors={colors} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/orders"
            element={
              <ProtectedRoute>
                <Orders user={user} />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <div className="main">
                <h2 style={{ marginLeft: 20 }}>404 Page not found</h2>
              </div>
            }
          />
        </Routes>
      </ErrorBoundary>
      <Footer onClick={handleToggleModal} />
      <FabricModal
        onClick={handleToggleModal}
        toggleModal={toggleModal}
        colors={colors}
      />
      <ToastContainer />
    </div>
  );
}

export default App;
