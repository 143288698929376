import React from "react";
const About = () => {
   return (
      <div className='main__background'>
         <main className='main container'>
            <h1>About me</h1>
            <div className='small-container block about--section'>
               <div className='about__image-wrapper'>
                  <img src='./images/nora.jpg' alt='' />
                  <div>
                     <sub>Photo: Caroline Roca</sub>
                  </div>
               </div>
               <div className='about__text'>
                  <h3>Nora Augustinius</h3>
                  <p>
                     My name is Nora and I am a ballet dancer with the Norwegian
                     National Ballet. I love to create!
                  </p>
                  <p>
                     Augustiniuswear started out with me sewing ballet skirts
                     for myself and friends. The different styles have been
                     named after and designed in collaboration with dancers of
                     the Norwegian National Ballet.
                  </p>
                  <p></p>
               </div>
            </div>
         </main>
      </div>
   );
};

export default About;
