import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
   removeItem,
   incrementItem,
   decrementItem,
} from "../../redux/reducers/cartSlice";

const CartItem = ({ item }) => {
   const dispatch = useDispatch();

   const handleClick = (e, item) => {
      const target = e.target;

      if (target.id === "increment") {
         try {
            dispatch(incrementItem(item));
         } catch (error) {
            toast.error(error.message);
         }
      } else {
         dispatch(decrementItem(item));
      }
   };

   return (
      <section className='cart--product-wrapper'>
         <div className='cart--product__image'>
            <img src={item.style.images[0].url} alt='' />
         </div>
         <div className='cart--product__details-wrapper'>
            <div className='cart--product__details'>
               <h2 className='cart--product-header'>{item.style.styleName}</h2>
               <span>
                  <strong>Color:</strong> {item.color.colorName}
               </span>
               <span>
                  <strong>Wasit band:</strong> {item.waistBand}
               </span>
               <span>
                  <strong>Fabric:</strong> {item.fabric.fabricName}
               </span>

               <p>{item.style.shortDescription}</p>
            </div>
            <div className='cart--product__amount'>
               <div
                  className='cart--item_decrement'
                  onClick={(e) => handleClick(e, item)}
               >
                  <i id='decrement' className='fa-solid fa-circle-minus'></i>
               </div>

               <div className='cart--item__number'>{item.quantity}</div>

               <div
                  className='cart--item_increment'
                  onClick={(e) => handleClick(e, item)}
               >
                  <i id='increment' className='fa-solid fa-circle-plus'></i>
               </div>
            </div>
            <div className='cart--product__price'>
               NOK {item.style.price.toFixed(2)}
            </div>
         </div>
         <div className='cart--product-delete'>
            <span>
               <i
                  className='icon icon--red fa-solid fa-trash-can'
                  onClick={() => dispatch(removeItem(item))}
               ></i>
            </span>
         </div>
      </section>
   );
};

export default CartItem;
