import React from "react";
const ImageThumbnails = ({ selectedProduct }) => {
   return selectedProduct.images.map((image) => {
      return (
         <span key={image.url}>
            <img
               src={image.url}
               alt='product'
               style={{
                  width: 100,
                  height: 100,
                  marginRight: 10,
               }}
            />
         </span>
      );
   });
};

export default ImageThumbnails;
