import React from "react";

import GalleryComponent from "./GalleryComponent";
import { getGalleryImages } from "../../api/httpServices";
import { useState } from "react";
import { useEffect } from "react";

const Gallery = () => {
   const [galleryImages, setGalleryImages] = useState([]);
   console.log(galleryImages[0]?.images);

   const fetchImages = async () => {
      const result = await getGalleryImages();

      if (!result.error) setGalleryImages(result[0].images);
   };

   useEffect(() => {
      if (!galleryImages.length) fetchImages();
   }, [galleryImages]);
   return (
      <div className='main container'>
         <h1>Gallery</h1>
         <GalleryComponent galleryImages={galleryImages} />
      </div>
   );
};

export default Gallery;
