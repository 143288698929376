import React from "react";
const TableHeader = ({ columns }) => {
   return (
      <thead>
         <tr>
            {columns.map((col) => {
               return (
                  <th className='tableHeader' key={col.path || col.key}>
                     {col.label}
                  </th>
               );
            })}
         </tr>
      </thead>
   );
};

export default TableHeader;
