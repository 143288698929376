import React from "react";
import ProductCard from "../common/productCard";

const AvailableProducts = ({ products, onClick }) => {
  return (
    <div className=" grid offer__grid small-container" data-aos="zoom-in-up">
      {products
        ? products.map((product) => {
            if (product.amountOnStock > 0) {
              return (
                <ProductCard
                  product={product}
                  onClick={() => onClick(product._id)}
                  key={product._id}
                />
              );
            }
          })
        : ""}
    </div>
  );
};

export default AvailableProducts;
