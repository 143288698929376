import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {
   setOrder,
   setHtmlSnippet,
   setKlarnaOrderNumber,
   setKlarnaStatus,
} from "../../redux/reducers/orderSlice";

import { API_BASE_URL } from "../../api/apiConfig";
import { getProduct } from "../../api/httpServices";
import { toast } from "react-toastify";

const CartTotal = ({ cart, klarna }) => {
   const dispatch = useDispatch();
   let navigate = useNavigate();
   const [code, setCode] = useState();

   const API_URL = API_BASE_URL + "klarna";

   const cartTotal = () => {
      let total = 0;
      cart.forEach((item) => {
         total += item.quantity * item.style.price;
      });
      return total.toFixed(2);
   };

   const sendKlarnaOrder = async (order) => {
      try {
         const response = await axios.post(API_URL, order);
         dispatch(setHtmlSnippet(response.data.html_snippet));
         dispatch(setKlarnaOrderNumber(response.data.order_id));
         dispatch(setKlarnaStatus("checkout"));
         return response.data;
      } catch (error) {
         console.log(error.message);
      }
   };

   const updateKlarnaOrder = async (order) => {
      try {
         const response = await axios.post(
            API_URL + "/update/" + klarna.klarnaOrderNumber,
            order
         );

         await axios.put(
            API_BASE_URL + "orders/" + klarna.klarnaOrderNumber,
            response
         );
         navigate("/checkout");
      } catch (error) {
         console.log(error);
      }
   };

   const handleChange = (e) => {
      setCode(e.target.value);
   };

   const checkStock = async () => {
      for (let i = 0; i < cart.length; i++) {
         try {
            const product = await getProduct(cart[i]._id);
            if (product[0].amountOnStock <= 0) {
               throw new Error(
                  `Sorry, the ${cart[i].style.styleName} ${cart[i].color.colorName} has already been sold!`
               );
            }
         } catch (error) {
            toast.error(error.message);
            return false;
         }
      }
      return true;
   };

   const handleCheckoutClick = async (e) => {
      e.preventDefault();
      //Check if product still on stock
      const onStock = await checkStock();
      if (!onStock) return;

      //Order lines for Klarna order
      const order_lines = cart.map((item) => {
         let product = item.style;

         return {
            reference: item._id,
            name: product.styleName,
            merchant_data:
               "Color: " +
               item.color.colorName +
               " Waist Band: " +
               item.waistBand +
               " Fabric: " +
               item.fabric.fabricName,
            quantity: item.quantity,
            unit_price: product.price * 100,
            tax_rate: 2500,
            total_amount: product.price * item.quantity * 100,
            total_tax_amount: ((product.price * item.quantity) / 5) * 100,
         };
      });

      //Order object to send to Klarna
      const order = {
         order_lines: order_lines,
         order_amount: cartTotal() * 100,
         order_tax_amount: (cartTotal() / 5) * 100,
      };
      if (code !== "OPERAEN")
         order.shipping_options = [
            {
               id: "Norgespakke",
               name: "Norgespakke",
               description: "Delivered in 3-5 days",
               promo: "",
               price: 4000,
               tax_amount: 800,
               tax_rate: 2500,
               shipping_method: "Post",
            },
         ];
      else order.shipping_options = [];

      //Add to state
      dispatch(setOrder(order));

      if (klarna.klarnaOrderNumber && klarna.klarnaStatus !== "confirm") {
         //Update exisiting order
         updateKlarnaOrder(order);
      } else if (klarna.klarnaStatus !== "confirm") {
         //Send order post to Klarna for checkout
         sendKlarnaOrder(order);

         navigate("/checkout");
      }
   };

   return (
      <div className='cart--total-wrapper'>
         <h2 className='cart--total-header'> Total to pay</h2>
         <hr />
         <div className='cart--shippingText'>
            Shipping costs will be added at checkout
         </div>
         <div className='cart--sum-wrapper'>
            <div>Sum</div>

            <div>{cartTotal()}</div>
         </div>
         <hr />

         <form onSubmit={handleCheckoutClick}>
            <div className='form-group' style={{ width: "50%" }}>
               <label htmlFor='code' style={{ fontSize: "14px" }}>
                  Discount code
               </label>
               <input
                  type='text'
                  name='code'
                  id='code'
                  className='form-control'
                  onChange={handleChange}
               />
            </div>
            <button className='btn btn--block btn--outline' type='submit'>
               Checkout
            </button>
         </form>
      </div>
   );
};

export default CartTotal;
