import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { addToCart } from "../../redux/reducers/cartSlice";

const ProductCard = ({ product }) => {
   const dispatch = useDispatch();
   const { style } = product;

   const handleClick = () => {
      try {
         dispatch(addToCart(product));
         toast.success("Product added to cart");
      } catch (error) {}
   };
   const RenderImage = () => {
      const image =
         style.images.length > 0 ? (
            <img
               src={style.images[0].url}
               alt='Product'
               className='product__image'
            />
         ) : (
            <img
               src='https://via.placeholder.com/300?text=No+Image+Available'
               alt='Product'
               className='product__image'
            />
         );
      return image;
   };

   return (
      <div className='product--card' data-aos='zoom-in-up'>
         {style.nyhet ? <div className='product--card-label'>Nyhet</div> : ""}
         <Link to={"/products/" + product._id}>
            <div className='product--card-header'>
               <RenderImage />
            </div>
         </Link>
         <div className='product--card-body'>
            <h4 className='product--card-heading'>{style.styleName}</h4>
            <p className='product--card-text'>{style.shortDescription}</p>
            <ul>
               <li>Fabric: {product.fabric.fabricName}</li>
               <li>Color: {product.color.colorName}</li>
               <li>Waist band: {product.waistBand}</li>
            </ul>
            <p className='product--card-price'>
               <strong>Kr {style.price.toFixed(2)},-</strong>
            </p>
         </div>
         <div className='product--card-footer'>
            {
               <button className='btn btn--outline' onClick={handleClick}>
                  Add to cart
               </button>
            }
         </div>
      </div>
   );
};

export default ProductCard;
