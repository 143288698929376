import React from "react";

import { API_BASE_URL } from "../../api/apiConfig";
import { IKContext, IKUpload } from "imagekitio-react";

const publicKey = "public_s0GVIAV3ngWyzp/MT3+Dgosr/4o=";
const urlEndpoint = "https://ik.imagekit.io/designedit";
const authenticationEndpoint = API_BASE_URL + "imagekit/auth";

const UploadImage = ({ filename, handleImageSubmit }) => {
   return (
      <IKContext
         urlEndpoint={urlEndpoint}
         publicKey={publicKey}
         authenticationEndpoint={authenticationEndpoint}
      >
         <IKUpload
            fileName={`${filename}.jpg`}
            folder={"/augustiniuswear"}
            onError={(err) => {
               console.log(err);
            }}
            onSuccess={handleImageSubmit}
         />
      </IKContext>
   );
};

export default UploadImage;
