import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../api/apiConfig";

const baseUrl = API_BASE_URL + "colors";

export const fetchColors = createAsyncThunk("colors/fetchColors", async () => {
   const response = await axios.get(baseUrl);
   return response.data;
});

export const colorSlice = createSlice({
   name: "colors",
   initialState: {
      colors: [],
      status: "idle", // 'idle' || 'loading' || 'succeeded' || 'failed'
      error: null,
   },
   reducers: {
      addColor: (state, action) => {
         state.colors.push(action.payload);
      },
      deleteColor: (state, action) => {
         const index = state.colors.findIndex(
            (color) => color._id === action.payload._id
         );
         state.colors.splice(index, 1);
      },
      updateColor: (state, action) => {
         const index = state.colors.findIndex(
            (color) => color._id === action.payload._id
         );
         state.colors[index] = action.payload;
      },
   },
   extraReducers(builder) {
      builder
         .addCase(fetchColors.pending, (state, action) => {
            state.status = "loading";
         })
         .addCase(fetchColors.fulfilled, (state, action) => {
            state.status = "succeeded";
            // Any code to add or change products
            //
            state.colors = action.payload;
         })
         .addCase(fetchColors.rejected, (state, action) => {
            state.status = "colors failed to fetch";
            state.error = action.error.message;
            console.log(action.error.message);
         });
   },
});

export const selectAllColors = (state) => state.colors.colors;
export const selectColorStatus = (state) => state.colors.status;
export const { addColor, deleteColor, updateColor } = colorSlice.actions;
export default colorSlice.reducer;
