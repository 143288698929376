import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
   getStyles,
   addStyleDb,
   updateStyleDb,
   deleteStyleDb,
} from "../../api/httpServices";

export const fetchStyles = createAsyncThunk("styles/fetchStyles", async () => {
   const response = await getStyles();
   return response;
});

export const addStyle = createAsyncThunk(
   "styles/addStyle",
   async (style, { rejectWithValue }) => {
      try {
         const response = await addStyleDb(style.style, style.headers);
         return response.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

export const updateStyle = createAsyncThunk(
   "styles/updateStyle",
   async (style, { rejectWithValue }) => {
      try {
         const result = await updateStyleDb(
            style._id,
            style.style,
            style.headers
         );
         return result.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

export const deleteStyle = createAsyncThunk(
   "styles/deleteStyle",
   async (style, { rejectWithValue }) => {
      try {
         const response = await deleteStyleDb(style._id, style.headers);
         return response.data;
      } catch (error) {
         return rejectWithValue(error.response.data);
      }
   }
);

const initialState = {
   styles: [],
   status: "idle", // 'idle' || 'loading' || 'succeeded' || 'failed'
   error: null,
};

export const styleSlice = createSlice({
   name: "styles",
   initialState: initialState,
   reducers: {},
   extraReducers(builder) {
      builder
         .addCase(fetchStyles.pending, (state, action) => {
            state.status = "loading";
         })
         .addCase(fetchStyles.fulfilled, (state, action) => {
            state.status = "succeeded";
            // Any code to add or change products
            //
            state.styles = action.payload;
         })
         .addCase(fetchStyles.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            console.log(action.error.message);
         })
         .addCase(addStyle.fulfilled, (state, action) => {
            state.styles.push(action.payload);
         })
         .addCase(updateStyle.fulfilled, (state, action) => {
            const index = state.styles.findIndex(
               (style) => style._id === action.payload._id
            );
            if (index !== -1) state.styles[index] = action.payload;
         })
         .addCase(deleteStyle.fulfilled, (state, action) => {
            const deleteItem = state.styles.filter(
               (style) => style._id !== action.payload._id
            );
            state.styles = deleteItem;
         });
   },
});
export const selectAllStyles = (state) => state.styles.styles;
export const getStyleStatus = (state) => state.styles.status;
export const getStyleError = (state) => state.styels.error;

export default styleSlice.reducer;
