import React from "react";
import KlarnaCheckout from "./KlarnaCheckoutSnippet";

const Checkout = ({ klarna }) => {
  return (
    <main className="main container">
      <h1>Checkout</h1>
      {klarna.html_snippet ? (
        <KlarnaCheckout snippet={klarna.html_snippet} />
      ) : null}
    </main>
  );
};

export default Checkout;
