import axios from "axios";
import { API_BASE_URL } from "./apiConfig";

/*Products */

export const getProducts = async () => {
   const result = await axios.get(API_BASE_URL + "products");
   return result.data;
};

export const getProduct = async (id) => {
   const result = await axios.get(API_BASE_URL + "products/" + id);
   return result.data;
};

export const updateProductDb = async (id, product, headers) => {
   return await axios.put(API_BASE_URL + "products/" + id, product, headers);
};

export const addProductDb = async (formData, headers) => {
   return await axios.post(API_BASE_URL + "products", formData, headers);
};

export const deleteProductDb = async (id, headers) => {
   try {
      return await axios.delete(API_BASE_URL + "products/" + id, headers);
   } catch (err) {
      console.log(err.message);
   }
};

export const decreaseStockDb = async (id, quantity) => {
   return await axios.put(API_BASE_URL + "products/stock/" + id, { quantity });
};

/* Styles*/

export const getStyles = async () => {
   try {
      const response = await axios.get(API_BASE_URL + "styles");

      return response.data;
   } catch (err) {
      console.log(err.message);
   }
};

export const addStyleDb = async (style, headers) => {
   return await axios.post(API_BASE_URL + "styles", style, headers);
};

export const updateStyleDb = async (id, style, headers) => {
   return await axios.put(API_BASE_URL + "styles/" + id, style, headers);
};

export const deleteStyleDb = async (id, headers) => {
   return await axios.delete(API_BASE_URL + "styles/" + id, headers);
};
/* Colors */

export const addColorDb = async (formData, headers) => {
   try {
      return await axios.post(API_BASE_URL + "colors", formData, headers);
   } catch (err) {
      console.log(err.message);
   }
};

export const updateColorDb = async (id, fabric, headers) => {
   try {
      return await axios.put(API_BASE_URL + "colors/" + id, fabric, headers);
   } catch (err) {
      console.log(err.message);
   }
};

export const deleteColorDb = async (id, headers) => {
   try {
      return await axios.delete(API_BASE_URL + "colors/" + id, headers);
   } catch (err) {
      console.log(err.message);
   }
};

/*Orders */

export const getOrders = async (headers) => {
   try {
      return await axios.get(API_BASE_URL + "orders", headers);
   } catch (err) {
      console.log(err.message);
   }
};

export const createOrder = async (order) => {
   try {
      await axios.post(API_BASE_URL + "orders", {
         order_id: order.order_id,
         status: order.status,
         billing_address: order.billing_address,
         shipping_address: order.shipping_address,
         order_amount: order.order_amount / 100,
         order_tax_amount: order.order_tax_amount / 100,
         order_lines: order.order_lines,
         started_at: order.started_at,
         last_modified_at: order.last_modified_at,
      });
      return true;
   } catch (error) {
      console.log(error.message);
      return error.message;
   }
};

export const confirmKlarnaOrder = async (id) => {
   try {
      const result = await axios.post(
         API_BASE_URL + "klarna/acknowledge/" + id
      );
      return result;
   } catch (error) {
      console.log(error.message);
      return error.message;
   }
};

/*Fabrics */
export const getFabrics = async (headers) => {
   try {
      return await axios.get(API_BASE_URL + "fabrics", headers);
   } catch (err) {
      console.log(err.message);
   }
};

/*Gallery images */

export const getGalleryImages = async () => {
   try {
      console.log("Getting images");
      const images = await axios.get(API_BASE_URL + "gallery");
      return images.data;
   } catch (error) {
      console.log(error.message);
   }
};
