import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOutUser } from "../redux/reducers/userSlice";
const Logout = ({ user }) => {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   useEffect(() => {
      if (user.token) {
         dispatch(logOutUser());
         navigate("/");
      }
   });
   return <div className='main container'></div>;
};

export default Logout;
