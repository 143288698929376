import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import { addStyle, updateStyle } from "../../../redux/reducers/styleSlice";
import { useNavigate } from "react-router-dom";

const Form = ({ selectedStyle, edit, headers }) => {
  const initialFormData = {
    styleName: "",
    shortDescription: "",
    longDescription: "",
    price: "",
    nyhet: "false",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateForm = () => {
    const { error } = schema.validate(formData, { abortEarly: false });

    const errors = {};

    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
      setFormErrors(errors);

      return false;
    }

    return true;
  };

  const schema = Joi.object({
    styleName: Joi.string().min(3).max(50).required().label("Product Name"),
    shortDescription: Joi.string()
      .min(5)
      .max(100)
      .required()
      .label("Short Description"),
    longDescription: Joi.string().max(500).empty(""),
    price: Joi.number().min(0).max(10000).required().label("Price"),
    nyhet: Joi.empty(""),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (!edit) {
      try {
        const response = await dispatch(
          addStyle({ style: formData, headers })
        ).unwrap();
        if (response.error) throw new Error(response.data.error);

        toast.success("Style added!");
        navigate(`/admin/styles/${response.data._id}`);
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      try {
        const response = await dispatch(
          updateStyle({ _id: selectedStyle._id, style: formData, headers })
        ).unwrap();
        if (response.error) throw new Error(response.error.data);

        toast.success("Style updated");
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (edit && selectedStyle) {
      setFormData({
        styleName: selectedStyle.styleName,
        shortDescription: selectedStyle.shortDescription,
        longDescription: selectedStyle.longDescription,
        price: selectedStyle.price,
        nyhet: selectedStyle.nyhet.toString(),
      });
    }
  }, [selectedStyle]);

  return (
    <form onSubmit={handleSubmit} className="flex--col">
      <div className="form-group">
        <label htmlFor="styleName">Style name</label>
        <input
          className="form-control"
          type="text"
          id="styleName"
          name="styleName"
          placeholder="Style Name"
          onChange={handleChange}
          value={formData.styleName}
        />
        {formErrors.styleName ? (
          <div className="error">{formErrors.styleName}</div>
        ) : (
          ""
        )}
      </div>
      <div className="form-group">
        <input
          className="form-control"
          type="text"
          id="shortDescription"
          name="shortDescription"
          placeholder="Short Description"
          onChange={handleChange}
          value={formData.shortDescription}
        />
        {formErrors.shortDescription ? (
          <div className="error">{formErrors.shortDescription}</div>
        ) : (
          ""
        )}
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          rows="4"
          id="longDescription"
          name="longDescription"
          placeholder="Long Description"
          onChange={handleChange}
          value={formData.longDescription}
        />
      </div>
      <div className="form-group">
        <input
          className="form-control"
          type="number"
          id="price"
          name="price"
          placeholder="Price"
          onChange={handleChange}
          value={formData.price}
        />
        {formErrors.price ? (
          <div className="error">{formErrors.price}</div>
        ) : (
          ""
        )}
      </div>
      <div className="form-group">
        <label>Nyhet:</label>
        <label>
          <input
            type="radio"
            name="nyhet"
            value="true"
            checked={formData.nyhet === "true"}
            onChange={handleChange}
          />
          Ja
        </label>
        <label>
          <input
            type="radio"
            name="nyhet"
            value="false"
            checked={formData.nyhet === "false"}
            onChange={handleChange}
          />
          Nei
        </label>
      </div>
      <button className="btn btn--outline">
        {edit ? "Update product" : "Add new product"}
      </button>
    </form>
  );
};

export default Form;
