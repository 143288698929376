import React from "react";
import { Link } from "react-router-dom";

const StyleCard = ({ style }) => {
   const RenderImage = () => {
      const image =
         style.images.length > 0 ? (
            <img
               src={style.images[0].url}
               alt='Product'
               className='product__image'
            />
         ) : (
            <img
               src='https://via.placeholder.com/300?text=No+Image+Available'
               alt='Product'
               className='product__image'
            />
         );
      return image;
   };

   return (
      <div className='product--card' data-aos='zoom-in-up'>
         {style.nyhet ? <div className='product--card-label'>Nyhet</div> : ""}
         <Link to={"/styles/" + style._id}>
            <div className='product--card-header'>
               <RenderImage />
            </div>
         </Link>
         <div className='product--card-body'>
            <h4 className='product--card-heading'>{style.styleName}</h4>
            <p className='product--card-text'>{style.shortDescription}</p>
            <p className='product--card-price'>
               <strong>Kr {style.price.toFixed(2)},-</strong>
            </p>
         </div>
         <div className='product--card-footer'></div>
      </div>
   );
};

export default StyleCard;
