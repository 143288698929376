import React from "react";
import _ from "lodash";

const TableBody = ({ columns, data }) => {
   const renderCell = (item, column) => {
      if (column.content) return column.content(item);
      let renderItem = _.get(item, column.path);
      return renderItem;
   };

   return (
      <tbody>
         {data?.map((item) => {
            return (
               <tr key={item._id}>
                  {columns.map((col) => {
                     return (
                        <td key={item._id + (col.path || col.key)}>
                           {renderCell(item, col)}
                        </td>
                     );
                  })}
               </tr>
            );
         })}
      </tbody>
   );
};

export default TableBody;
