import { createSlice } from "@reduxjs/toolkit";

const initialState = { cart: [] };

export const cartSlice = createSlice({
   name: "cart",
   initialState: initialState,
   reducers: {
      addToCart: (state, action) => {
         const itemInCart = state.cart.find(
            (item) => item._id === action.payload._id
         );
         if (itemInCart) {
            if (itemInCart.quantity + 1 > itemInCart.amountOnStock)
               throw new Error("No more of this item on stock");

            itemInCart.quantity++;
         } else {
            let item = action.payload;
            state.cart.push({ ...item, quantity: 1 });
         }
      },
      removeItem: (state, action) => {
         const removeItem = state.cart.filter(
            (item) => item._id !== action.payload._id
         );

         state.cart = removeItem;
      },
      incrementItem: (state, action) => {
         const item = state.cart.find(
            (item) => item._id === action.payload._id
         );

         if (item.quantity + 1 > item.amountOnStock)
            throw new Error("No more of this item on stock");

         item.quantity++;
      },
      decrementItem: (state, action) => {
         const item = state.cart.find(
            (item) => item._id === action.payload._id
         );
         if (item.quantity > 1) item.quantity--;
      },
      clearCart: (state) => {
         state.cart = initialState.cart;
      },
   },
});

export const {
   addToCart,
   removeItem,
   incrementItem,
   decrementItem,
   clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
