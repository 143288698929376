import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import { selectAllColors } from "../../../redux/reducers/colorSlice";
import { selectAllStyles } from "../../../redux/reducers/styleSlice";
import { getFabrics } from "../../../api/httpServices";
import {
  addProduct,
  updateProduct,
} from "../../../redux/reducers/productsSlice";

const Form = ({ selectedProduct, edit, headers }) => {
  const initialFormData = {
    style: selectedProduct?.style._id || "",
    color: selectedProduct?.color._id || "",
    fabric: selectedProduct?.fabric._id || "",
    waistBand: selectedProduct?.waistBand || "",
    amountOnStock: selectedProduct?.amountOnStock || "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [fabrics, setFabrics] = useState();
  const dispatch = useDispatch();
  const styles = useSelector(selectAllStyles);
  const colors = useSelector(selectAllColors);

  const validateForm = () => {
    const { error } = schema.validate(formData, { abortEarly: false });

    const errors = {};

    if (error) {
      for (let item of error.details) errors[item.path[0]] = item.message;
      setFormErrors(errors);
      console.log(errors);
      return false;
    }

    return true;
  };

  const schema = Joi.object({
    style: Joi.string().min(3).max(50).required().label("Style Name"),
    color: Joi.string().min(3).max(50).required().label("Color Name"),
    fabric: Joi.string().min(3).max(50).required().label("Fabric Name"),
    waistBand: Joi.string().required().label("Waist band"),
    amountOnStock: Joi.number().min(0).required().label("Amount on stock"),
  });

  const fetchFabrics = async () => {
    const result = await getFabrics(headers);
    setFabrics(result.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    if (!edit) {
      try {
        const result = dispatch(
          addProduct({ product: formData, headers })
        ).unwrap();
        if (result.error) throw new Error(result.error);
        toast.success("Product added to store");
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      try {
        const product = {
          _id: selectedProduct._id,
          product: formData,
          headers,
        };
        const result = await dispatch(updateProduct(product)).unwrap();
        if (result.error) throw new Error(result.error);
        toast.success("Product updated");
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    fetchFabrics();
  }, []);
  useEffect(() => {
    if (selectedProduct) {
      const values = {
        style: selectedProduct.style._id,
        color: selectedProduct.color._id,
        fabric: selectedProduct.fabric._id,
        waistBand: selectedProduct.waistBand,
        amountOnStock: selectedProduct.amountOnStock,
      };
      setFormData(values);
    }
  }, [selectedProduct]);

  return (
    <form onSubmit={handleSubmit} className="flex--col">
      <div className="form-group">
        <label htmlFor="style">Style name</label>
        <select
          className="form-control"
          type="text"
          id="style"
          name="style"
          onChange={handleChange}
          value={formData.style}
        >
          <option value={null}>--Select Style---</option>
          {styles?.map((style) => (
            <option value={style._id} key={style._id}>
              {style.styleName}
            </option>
          ))}
        </select>
        {formErrors.styleName ? (
          <div className="error">{formErrors.styleName}</div>
        ) : (
          ""
        )}
      </div>
      <div className="form-group">
        <label htmlFor="color">Colour</label>
        <select
          className="form-control"
          type="text"
          id="color"
          name="color"
          onChange={handleChange}
          value={formData.color}
        >
          <option value={null}>--Select Colour---</option>
          {colors.map((color) => (
            <option value={color._id} key={color._id}>
              {color.colorName}
            </option>
          ))}
        </select>
        {formErrors.styleName ? (
          <div className="error">{formErrors.colorName}</div>
        ) : (
          ""
        )}
      </div>
      <div className="form-group">
        <label htmlFor="fabric">Fabric name</label>
        <select
          className="form-control"
          type="text"
          id="fabric"
          name="fabric"
          onChange={handleChange}
          value={formData.fabric}
        >
          <option value={null}>--Select fabric---</option>
          {fabrics?.map((fabric) => (
            <option value={fabric._id} key={fabric._id}>
              {fabric.fabricName}
            </option>
          ))}
        </select>
        {formErrors.fabricName ? (
          <div className="error">{formErrors.fabricName}</div>
        ) : (
          ""
        )}
      </div>
      <div className="form-group">
        <label htmlFor="waistBand">Waist band</label>
        <select
          className="form-control"
          type="text"
          id="waistBand"
          name="waistBand"
          onChange={handleChange}
          value={formData.waistBand}
        >
          <option value={null}>--Select Waistband---</option>
          <option value="Black">Black</option>
          <option value="White">White</option>
        </select>
        {formErrors.waistBand ? (
          <div className="error">{formErrors.waistBand}</div>
        ) : (
          ""
        )}
      </div>
      <div className="form-group">
        <label htmlFor="amountOnStock">Amount on stock</label>
        <input
          className="form-control"
          type="number"
          id="amountOnStock"
          name="amountOnStock"
          onChange={handleChange}
          value={formData.amountOnStock}
        />
      </div>

      <button className="btn btn--outline">
        {edit ? "Update product" : "Add new product"}
      </button>
    </form>
  );
};

export default Form;
