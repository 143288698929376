import React from "react";
import RenderIKImage from "../admin/RenderImage";
import imageFunctions from "../../utilities/imageKitFunctions";

const FabricModal = ({ onClick, toggleModal, colors }) => {
   return (
      <div className={toggleModal ? "modal show__modal" : "modal"}>
         <div className='modal__content'>
            <span className='close__modal' onClick={onClick}>
               &times;
            </span>
            <header className='modal__header'>
               <h2>Colours</h2>
            </header>
            <div className='fabrics--grid'>
               {colors.map((color) => {
                  return (
                     <div className='fabric__details' key={color._id}>
                        <div className='fabric_images'>
                           <div className='fabric__image'>
                              <RenderIKImage
                                 path={imageFunctions.getPath(
                                    color.images[0].url
                                 )}
                                 transformation={[{ height: 300, width: 300 }]}
                              />
                           </div>

                           {color.images[1] ? (
                              <div className='fabric__image'>
                                 <RenderIKImage
                                    path={imageFunctions.getPath(
                                       color.images[1].url
                                    )}
                                    transformation={[
                                       { height: 300, width: 300 },
                                    ]}
                                 />
                              </div>
                           ) : null}
                        </div>

                        <p className='image__subtext'>{color.colorName}</p>
                     </div>
                  );
               })}
            </div>
         </div>
      </div>
   );
};

export default FabricModal;
