import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchStyles, selectAllStyles } from "../../redux/reducers/styleSlice";

import StyleCard from "./StyleCard";
import ProductInfo from "../common/ProductInfo";
import ItemDetails from "../common/ItemDetails";

const Styles = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const styles = useSelector(selectAllStyles);
  let style = null;

  style = styles?.find((s) => s._id === params.id);

  if (!styles) dispatch(fetchStyles());

  return (
    <div className=" main__background">
      <main className="main container">
        {style ? <h1>Style details</h1> : <h1>Styles</h1>}

        {style ? (
          <div className="block">
            <ItemDetails style={style} />
          </div>
        ) : (
          <>
            <ProductInfo />
            <div className=" block grid small-container  ">
              {styles?.map((style) => {
                return <StyleCard style={style} key={style._id} />;
              })}
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Styles;
