import React from "react";

const ProductInfo = () => {
   return (
      <div>
         <p>
            All the styles come as wrap skirts with an elastic waist band.
            Measurements are approximate due to the hand-cut nature.
         </p>
         <p>
            There are two different fabric options with slightly different
            qualitites. Crepe Georgette and Chiffon.
         </p>
         <p>
            Crepe Georgette is slightly heavier and gives full coverage. Chiffon
            is lighter, thinner and slightly transparent.
         </p>
         <p>Both have a nice, flowy quality</p>
      </div>
   );
};

export default ProductInfo;
