import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import Table from "../../common/table";
import { deleteColorDb } from "../../../api/httpServices";
import { deleteColor } from "../../../redux/reducers/colorSlice";
import DeleteModal from "../deleteModal";
import RenderIKImage from "../RenderImage";
import IKFunctions from "../../../utilities/imageKitFunctions";

const ColorsTable = ({ colors, user }) => {
   const [toggleModal, setToggleModal] = useState(false);
   const [deleteId, setDeleteId] = useState(null);

   const dispatch = useDispatch();

   const headers = {
      headers: {
         Authorization: "Bearer " + user.token,
      },
   };

   const handleClick = async (id) => {
      if (!toggleModal) {
         setToggleModal(true);
         setDeleteId(id);
         return;
      }

      try {
         await deleteColorDb(id, headers);
         dispatch(deleteColor({ _id: id }));
         setDeleteId(null);
      } catch (error) {
         console.log(error.message);
      }
   };

   const columns = [
      {
         path: "colorName",
         label: "Name",
         content: (color) => {
            return (
               <Link to={`/admin/colors/${color._id}`}>{color.colorName}</Link>
            );
         },
      },
      {
         path: "color.images",
         label: "Image",
         content: (color) => {
            return (
               <div
                  style={{ display: "flex", height: "100px", width: "200px" }}
               >
                  {color.images.map((image) => (
                     <div key={image.url}>
                        <RenderIKImage
                           path={IKFunctions.getPath(image.url)}
                           transformation={[{ height: 100, width: 100 }]}
                        />
                     </div>
                  ))}
               </div>
            );
         },
      },

      {
         key: "delete",
         content: (color) => {
            return (
               <button
                  className='btn btn--outline'
                  onClick={() => handleClick(color._id)}
               >
                  Delete
               </button>
            );
         },
      },
   ];

   const handleDelete = (status) => {
      if (status) {
         handleClick(deleteId);
      }
      setToggleModal(false);
   };

   return (
      <main className='main container '>
         <h1>Colours in DB</h1>

         <div>
            <Link to='/admin/fabrics/fabric'>
               <button className='btn btn--outline'>New color</button>
            </Link>
         </div>
         <div className='productsTable'>
            <Table columns={columns} data={colors} />
         </div>
         <div>{toggleModal ? <DeleteModal onClick={handleDelete} /> : ""}</div>
      </main>
   );
};

export default ColorsTable;
